<template>
  <div>
    <el-card shadow="never" :body-style="{ padding: '10px 20px' }" style="margin-top:5px">
     
    <strong  style=" border-bottom: 1px solid #000;" ><i class="el-icon-menu"></i>
                 Productos</strong>
    
    <el-table :data="data" fit :height="height" size="mini">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="nombre" label="Nombre / Cod">
        <template slot-scope="scope">
          {{ scope.row.nombre }} <strong>{{ scope.row.codigo }} </strong>
          <br />
          <small style="color: #e91e63" v-if="scope.row.bultos">
            bultos: {{ scope.row.bultos }} - unidades x bulto:
            {{ scope.row.xbulto }}
          </small>
        </template>
      </el-table-column>
      <el-table-column prop="cantidad" label="Cantidad" width="70">
      </el-table-column>
      <el-table-column prop="precio_compra" label="Precio sin IVA" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.oferta" style="color: #2196f3">
            {{ scope.row.precio_compra | currency }}
          </span>
          <span v-else>
            {{ scope.row.precio_compra | currency }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="precio_compra"
        label="Subtotal sin IVA"
        width="130"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.oferta" style="color: #2196f3">
            {{ (scope.row.precio_compra * scope.row.cantidad) | currency }}
          </span>
          <span v-else>
            {{ (scope.row.precio_compra * scope.row.cantidad) | currency }}
          </span>
        </template>
      </el-table-column>
        <el-table-column
        prop="stock"
        label="Hay Stock"
        width="60"
       
      >
        <template slot-scope="scope">
          <span v-if="scope.row.stock == 0 " style="color: #eb1212">
           NO
          </span>
          <span v-if="scope.row.stock == 1 " style="color: #59be15">
       SI
          </span>
          <span v-if="!scope.row.stock " style="color: #c9c60b">
           Sin revisar
          </span>
        </template>
      </el-table-column>
    </el-table>
      </el-card>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      height: "500px"
    };
  },
  mounted() {
    //   this.height=this.data.length>10?'550':(this.data.length*75).toString();
       
  },
  methods: {
    totales() {
      let totales = {};

      this.total_cantidad = totales.cantidad = this.data.reduce((a, b) => {
        return a + b.cantidad;
      }, 0);
      totales.unitarios = this.data.reduce((u, uw) => {
        return parseFloat(u) + parseFloat(uw.precio_compra);
      }, 0);
      this.total_pedido = totales.total = this.data.reduce((t, tu) => {
        return parseFloat(t) + parseFloat(tu.precio_compra) * tu.cantidad;
      }, 0);

      totales.ofertas = this.data.filter((o) => {
        return o.oferta > 0;
      }).length;
      // console.log(totales);
      return totales;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>